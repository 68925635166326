import React from "react";
import Image1 from "../../assets/Images/Clutch/Footer/1.svg";
import Image2 from "../../assets/Images/Clutch/Footer/2.svg";
import Image3 from "../../assets/Images/Clutch/Footer/3.svg";
import "./style.scss";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { useContactModal } from "../Modal/contactModalContext";
import RevealAnimation from "../RevealAnimation";

function LPFooter({
  title,
  showCta = true,
  containerClass = "",
  ctaText = "Contact us",
  ctaClick,
}) {
  const { setOpenModal } = useContactModal();
  return (
    <div className="lp-footer-wrap">
      <div className={`body-container lp-footer-container ${containerClass}`}>
        <div className="lp-footer-content">
          <RevealAnimation className="title">
            {title || (
              <>
                Are you prepared to speed up software <br />
                development at your business?
              </>
            )}
          </RevealAnimation>
          {showCta && (
            <RevealAnimation
              component={"button"}
              className="submit-btn"
              onClick={() => {
                ctaClick ? ctaClick() : setOpenModal(true);
              }}
            >
              {ctaText}
              <span className="btn-icon">
                <KeyboardArrowRightOutlinedIcon />
              </span>
            </RevealAnimation>
          )}
        </div>
        <div className="lp-footer-img-wrap">
          <RevealAnimation
            component={"img"}
            animation="zoom-in"
            src={Image2}
            alt=""
          />
          <RevealAnimation
            component={"img"}
            animation="zoom-in"
            src={Image1}
            alt=""
          />
          <RevealAnimation
            component={"img"}
            animation="zoom-in"
            src={Image3}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default LPFooter;
