import React, { useEffect } from "react";
import sal from "sal.js";

// animation:
//     | "fade"
//     | "slide-up"
//     | "slide-down"
//     | "slide-right"
//     | "slide-left"
//     | "zoom-in"
//     | "zoom-out"
//     | "flip-up"
//     | "flip-down"
//     | "flip-left"
//     | "flip-right"

function RevealAnimation({
  className,
  animation = "slide-left",
  duration = 1000,
  delay = 100,
  children,
  component,
  style = {},
  ...rest
}) {
  const Component = component || "div";

  useEffect(() => {
    sal();
  }, []);

  return (
    <Component
      className={className}
      data-sal={animation}
      data-sal-duration={duration.toString()}
      data-sal-delay={delay.toString()}
      data-sal-easing="ease"
      style={style}
      {...rest}
    >
      {children}
    </Component>
  );
}

export default RevealAnimation;
